<template>
    <article v-if="evento">

        <section class="eventoInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="evento.translations && evento.translations[0]?.body" :body="evento.translations[0]?.body" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="evento.media && evento.media.length == 1" :image="evento.media[0]" />
                        <ModuloMedia v-else-if="evento.media && evento.media.length > 1" :media="evento.media" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration> 
                            <LazySezioneContatti :contatti="evento" />
                        </LazySkipHydration>

                        <!-- ORARI / TARIFFE / FILE -->
                        <SezioneCardEvento :evento="evento" />
                        <SezioneAccordionEvento :evento="evento" />

                        <!-- MACROEVENTI / SOTTOEVENTI -->
                        <ListaMacroeventi v-if="evento.inserito_in && evento.inserito_in.length > 0" :macroeventi="evento.inserito_in" />
                        <ListaSottoeventi v-if="(evento.sottoeventi && evento.sottoeventi.length > 0) || (evento.spettacoli && evento.spettacoli.length > 0) || (evento.visite_guidate && evento.visite_guidate.length > 0)" :sottoeventi="evento.sottoeventi" :spettacoli="evento.spettacoli" :visiteGuidate="evento.visite_guidate" />

                    </div>
                    <aside>

                        <!-- PRODOTTI -->
                        <ListaSideProdotti v-if="evento.aderisce_a && evento.aderisce_a.length > 0" :prodotti="evento.aderisce_a" />

                    </aside>
                </div>
            </div>
        </section>

        <!-- PACCHETTI -->
        <ModuloCarousel v-if="moduloPacchetti" :modulo="moduloPacchetti" size="small" />

        <!-- MAPPA -->
        <MappaSottoeventi v-if="evento.tipologia == 'macroevento' && ((evento.sottoeventi && evento.sottoeventi.length > 0) || (evento.spettacoli && evento.spettacoli.length > 0) || (evento.visite_guidate && evento.visite_guidate.length > 0))" :sottoeventi="evento.sottoeventi" :spettacoli="evento.spettacoli" :visiteGuidate="evento.visite_guidate" />
        <MappaCoordinate v-else-if="evento.geolocalizzazione && evento.geolocalizzazione.coordinates" :latitude="evento.geolocalizzazione.coordinates[1]" :longitude="evento.geolocalizzazione.coordinates[0]" />

        <!-- SEDI -->
        <ListaSedi v-if="evento.ospitato_in && evento.ospitato_in.length > 0" :sedi="evento.ospitato_in" />

        <!-- PAGINE COLLEGATE -->
        <ListaPagineCollegate v-if="evento.pagine_collegate && evento.pagine_collegate.length > 0" :pagine="evento.pagine_collegate" />

        <!-- ACCORDION TARGET -->
        <SezioneAccordionTarget :risorsa="evento" />

        <!-- MODULI -->
        <template v-for="modulo in evento.moduli">
            <ModuloCarousel v-if="modulo.item && modulo.item.__typename == 'modulo_carousel'" :modulo="modulo.item" />
            <ModuloCarouselSocial v-else-if="modulo.item && modulo.item.__typename == 'modulo_carousel_social'" :modulo="modulo.item" />
        </template>

    </article>
</template>

<script lang="ts" setup>
const { localeProperties, t } = useI18n()
const { seEventoPassato, sortEventiByOrario } = useOrari()
const { getEventoType, findStartDate, findEndDate } = useSchema()
const { dizionario } = useSitemap()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()

const props = defineProps<{ evento: any, routes: any | null }>()

const moduloPacchetti = computed(() => {
    if (props.evento.pacchetti && props.evento.pacchetti.length > 0) {
        const pacchetti = props.evento.pacchetti
        if (pacchetti.length == 0) return null

        const sortedPacchetti = sortEventiByOrario(pacchetti, 'pacchetti_id', 'asc')

        return {
            contenuto: 'manuale',
            translations: [
                {
                    titolo: t('proposte_per_te')
                }
            ],
            card: sortedPacchetti.map((e: any) => {
                return {
                    item: {
                        __typename: 'pacchetti',
                        ...e
                    }
                }
            })
        }
    } else return null
})

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }],
    meta: [
        { name: 'city', content: () => props.evento && props.evento.comune ? props.evento.comune.nome : null },
    ]
});
useSeoMeta({
    title: () => props.evento && props.evento.translations && props.evento.translations[0]?.og_title ? `${props.evento.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.evento && props.evento.translations && props.evento.translations[0]?.og_title ? `${props.evento.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.evento && props.evento.translations && props.evento.translations[0]?.og_title ? `${props.evento.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,

    description: () => props.evento && props.evento.translations && props.evento.translations[0]?.og_description ? props.evento.translations[0].og_description : null,
    ogDescription: () => props.evento && props.evento.translations && props.evento.translations[0]?.og_description ? props.evento.translations[0].og_description : null,
    twitterDescription: () => props.evento && props.evento.translations && props.evento.translations[0]?.og_description ? props.evento.translations[0].og_description : null,

    ogImage: () => props.evento && props.evento.cover && props.evento.cover.height && props.evento.cover.width ? `${directusUrl}assets/${props.evento.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.evento && props.evento.cover && props.evento.cover.height && props.evento.cover.width ? `${directusUrl}assets/${props.evento.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (props.routes && route.name.indexOf('preview') != 0) setI18nParams(props.routes)

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    name: dizionario.titoloVisita[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: dizionario.titoloEventi[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkEventi[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 3,
                    name: props.evento.translations[0].titolo,
                    item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                }
            ]
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.evento && props.evento.translations && props.evento.translations[0]?.og_title ? props.evento.translations[0].og_title : undefined,
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.evento && props.evento.cover && props.evento.cover.height && props.evento.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.evento.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.evento.cover.height * 1600 / props.evento.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.evento.cover.title
            } : undefined,
            datePublished: props.evento && props.evento.date_created ? new Date(props.evento.date_created).toJSON() : undefined,
            dateModified: props.evento && props.evento.date_updated ? new Date(props.evento.date_updated).toJSON() : undefined,
            description: props.evento && props.evento.translations && props.evento.translations[0]?.og_description ? props.evento.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org/${localeProperties.value.code}`
                    ]
                }
            ]
        },
        {
            '@type': getEventoType(props.evento),
            '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#event`,
            name: props.evento && props.evento.translations && props.evento.translations[0]?.og_title ? props.evento.translations[0].og_title : undefined,
            description: props.evento && props.evento.translations && props.evento.translations[0]?.og_description ? props.evento.translations[0].og_description : undefined,
            image: props.evento.media ? props.evento.media.map((m: any) => `${directusUrl}assets/${m.directus_files_id.id}?width=1600&format=jpeg`) : undefined,
            url: props.evento.link_esterni && props.evento.link_esterni.filter((l: any) => l.tipologia == 'website').length > 0 ? props.evento.link_esterni.filter((l: any) => l.tipologia == 'website')[0].link : undefined,
            eventStatus: "https://schema.org/EventScheduled",
            eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
            location: props.evento.indirizzo ? {
                "@type": "Place",
                address: {
                    "@type": "PostalAddress",
                    streetAddress: props.evento.indirizzo,
                    addressLocality: props.evento.comune ? props.evento.comune.nome : undefined,
                    postalCode: props.evento.cap,
                    addressCountry: "IT"
                }
            } : undefined,
            offers: props.evento.link_esterni && props.evento.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').length > 0 ?
                props.evento.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').map((l: any) => ({
                    '@type': 'Offer',
                    url: l.link,
                })) : undefined,
            sameAs: props.evento.link_esterni ? props.evento.link_esterni.filter((l: any) => l.tipologia != 'acquista' && l.tipologia != 'prenota').map((l: any) => l.link) : undefined,
            startDate: findStartDate(props.evento.date),
            endDate: findEndDate(props.evento.date)
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .eventoInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .eventoInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>