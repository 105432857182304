<template>
    <section class="zonaAccordion" v-if="evento.translations && evento.translations.length > 0 && ((evento.tariffe && evento.tariffe.length > 0) || (mixDate.length > 0) || (evento.translations_orari && evento.translations_orari.length > 0))">
        <div class="cont">
            <div class="innerCont">
                <div class="lista">
                    <AccordionContainer :title="$t('accordion_tariffe_titolo')" :open="false" v-if="evento.tariffe && evento.tariffe.length > 0">
                        <template #content>
                            <div class="tariffe">
                                <OggettoTariffa v-for="tariffa in evento.tariffe" :tariffa="tariffa" :total="evento.tariffe.length" />
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_orari_titolo')" :open="false" v-if="mixDate.length > 0">
                        <template #content>
                            <div class="orari">
                                <div class="headOrari" v-if="evento.usa_orari_sede && (evento.data_inizio_orari_sede || evento.data_fine_orari_sede)">
                                    <div class="titlebody upp">
                                        <span v-if="cut.inizio && cut.fine && cut.inizio.getTime() == cut.fine.getTime()">{{ formatoDataLungo(cut.inizio) }}</span>
                                        <span v-else-if="cut.inizio && cut.fine">{{ $t('data_mdal') }} {{ formatoDataCompatto(cut.inizio) }} {{ $t('data_al') }} {{ formatoDataCompatto(cut.fine) }}</span>
                                        <span v-else-if="cut.inizio && !cut.fine">{{ $t('data_mdal') }} {{ formatoDataCompatto(cut.inizio) }}</span>
                                        <span v-else-if="!cut.inizio && cut.fine">{{ $t('data_mfinoal') }} {{ formatoDataCompatto(cut.fine) }}</span>
                                    </div>
                                </div>
                                <OggettoOrario v-for="orario in mixDate" :orario="orario" />
                            </div>
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('accordion_materiali_scaricabili_titolo')" :open="false" v-if="evento.translations[0].allegati && evento.translations[0].allegati.length > 0">
                        <template #content>
                            <div class="allegati">
                                <template v-for="allegato in evento.translations[0].allegati">
                                    <OggettoFile v-if="allegato.directus_files_id && allegato.directus_files_id.title" :etichetta="allegato.directus_files_id.title" :file="allegato.directus_files_id" fillColor="text" />
                                </template>
                            </div>
                        </template>
                    </AccordionContainer>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const { getUTCMidnightDate, formatoDataCompatto, formatoDataLungo } = useOrari()
const props = defineProps<{ evento: any }>()

const cut = reactive({
    inizio: props.evento.data_inizio_orari_sede ? getUTCMidnightDate(props.evento.data_inizio_orari_sede) : null,
    fine: props.evento.data_fine_orari_sede ? getUTCMidnightDate(props.evento.data_fine_orari_sede) : null
})

const mixDate = computed(() => {
    if (props.evento.usa_orari_sede === true && props.evento.sede && props.evento.sede.length > 0) {
        const mixDate = <Orario[]>[]

        if (props.evento.sede[0].risorse_id.orari && props.evento.sede[0].risorse_id.orari.length > 0) mixDate.push(...props.evento.sede[0].risorse_id.orari)
        if (props.evento.sede[0].risorse_id.orari_straordinari && props.evento.sede[0].risorse_id.orari_straordinari.length > 0) mixDate.push(...props.evento.sede[0].risorse_id.orari_straordinari)

        return mixDate.filter((d: Orario) => {
            const dataInizio = d.data_inizio ? getUTCMidnightDate(d.data_inizio) : null
            const dataFine = d.data_fine ? getUTCMidnightDate(d.data_fine) : null
            const inizioRicorrenza = d.inizio_ricorrenza ? getUTCMidnightDate(d.inizio_ricorrenza) : null
            const fineRicorrenza = d.fine_ricorrenza ? getUTCMidnightDate(d.fine_ricorrenza) : null

            switch (d.ricorrenza) {
                case 'mai':
                    if (cut.inizio && dataFine && cut.inizio.getTime() > dataFine.getTime()) return false
                    if (cut.fine && dataInizio && cut.fine.getTime() < dataInizio.getTime()) return false
                    return true
                case 'giornaliera':
                case 'settimanale':
                case 'mensile':
                    if (cut.inizio && fineRicorrenza && cut.inizio.getTime() > fineRicorrenza.getTime()) return false
                    if (cut.fine && inizioRicorrenza && cut.fine.getTime() < inizioRicorrenza.getTime()) return false
                    return true
                default:
                    return true
            }
        })
    }
    else return props.evento.date
})
</script>

<style lang="scss" scoped>
.zonaAccordion {
    .upp {
        text-transform: uppercase;
    }

    .lista {
        display: flex;
        flex-direction: column;
        gap: var(--margin-gap);

        .orari,
        .tariffe {
            display: flex;
            flex-direction: column;
            gap: var(--margin-default);
        }
    }
}
</style>